<template>
  <div class="content">
    <!-- 封面图 -->
    <div class="cover cursor" @click="toIndex">
      <img src="../assets/imgs/gaokao_app/m1.gif" mode="widthFix" />
    </div>
    <!-- 表单 -->
    <div class="form">
      <div class="item">
        <span>学生姓名</span>
        <input
          type="span"
          v-model="form.userName"
          placeholder="请输入学生姓名"
          placeholder-class="phcolor"
        />
      </div>
      <div class="item">
        <span>文/理科</span>
        <div class="options">
          <div
            class="common"
            @click="onClickItem(option, index)"
            :class="{ active: index == currentIndex }"
            v-for="(option, index) in options"
            :key="index"
          >
            {{ option.title }}
          </div>
        </div>
      </div>
      <div class="item">
        <span>联系电话</span>
        <input
          type="span"
          v-model="form.userPhone"
          placeholder="请输入联系电话"
          placeholder-class="phcolor"
        />
      </div>
      <div class="item">
        <span></span>
        <input
          v-model="form.code"
          type="span"
          placeholder="请输入验证码"
          placeholder-class="phcolor"
        />
        <div class="code" @click="is_click && get_code()">{{ verify }}</div>
      </div>
      <div class="button" @click="submit">立即获取</div>
      <div class="tip">望子成龙郑重承诺：所有信息严格保密</div>
    </div>
    <div class="cover">
      <img src="../assets/imgs/gaokao_app/m2.gif" mode="widthFix" />
      <img
        src="../assets/imgs/gaokao_app/m3.gif"
        mode="widthFix"
        @click="contact"
      />
      <img src="../assets/imgs/gaokao_app/m4.gif" mode="widthFix" />
    </div>
    <!-- 轮播图 -->
    <div class="swiper-container banner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in swipers" :key="item.id">
          <img :src="item.img" />
        </div>
      </div>
    </div>
    <!-- 详情图 -->
    <div class="cover">
      <img src="../assets/imgs/gaokao_app/m5.gif" mode="widthFix" />
      <img src="../assets/imgs/gaokao_app/m6.gif" mode="widthFix" />
      <img src="../assets/imgs/gaokao_app/m7.gif" mode="widthFix" />
      <!-- <img src="../assets/imgs/gaokao_app/m8.jpg" mode="widthFix" /> -->
      <!-- <img
        src="../assets/imgs/gaokao_app/m9.gif"
        mode="widthFix"
        @click="contact"
      /> -->
      <img src="../assets/imgs/gaokao_app/m10.gif" mode="widthFix" />
      <img src="../assets/imgs/gaokao_app/m11.gif" mode="widthFix" />
      <img
        src="../assets/imgs/gaokao_app/m12.gif"
        mode="widthFix"
        @click="contact"
      />
      <img src="../assets/imgs/gaokao_app/m13.jpg" mode="widthFix" />
      <img
        src="../assets/imgs/gaokao_app/m14.gif"
        mode="widthFix"
        @click="contact"
      />
      <img src="../assets/imgs/gaokao_app/m15.gif" mode="widthFix" />
    </div>
    <!-- 消息弹窗 -->
    <popup ref="popup" :text="text" :time="3e3"></popup>
  </div>
</template>
<script>
import { sendSms, submitUserInfo } from "@/api/api.js";
import Popup from "@/components/popup";
import Swiper from "swiper/dist/js/swiper.js";
export default {
  components: { Popup },
  data() {
    return {
      //选项卡
      options: [
        {
          id: 0,
          title: "文科",
        },
        {
          id: 1,
          title: "理科",
        },
      ],
      currentIndex: -1, //选项卡当前选项
      swipers: [
        {
          id: 0,
          img: require("../assets/imgs/gaokao_app/ban1.jpg"),
        },
        {
          id: 1,
          img: require("../assets/imgs/gaokao_app/ban2.gif"),
        },
        {
          id: 2,
          img: require("../assets/imgs/gaokao_app/ban3.jpg"),
        },
        {
          id: 3,
          img: require("../assets/imgs/gaokao_app/ban4.jpg"),
        },
        {
          id: 4,
          img: require("../assets/imgs/gaokao_app/ban5.jpg"),
        },
      ],
      verify: "获取验证码", //获取验证码
      count_down: 60, //验证倒计时
      is_click: true, //获取验证码是否禁用
      form: {
        userName: "", //姓名
        message: "", //文理科
        userPhone: "", //联系电话
        code: "", //验证码
        comeFrom: "高考冲刺移动端",
      },
      phoneRule: /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-7|9])|(?:5[0-3|5-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1|8|9]))\d{8}$/,
      text: "", //弹窗内容
    };
  },
  onLoad() {},
  mounted() {
    new Swiper(".banner", {
      loop: true,
      speed: 300,
      observer: true,
      grabCursor: true,
      updateOnWindowResize: true,
      autoplay: true,
    });
  },
  methods: {
    //去首页
    toIndex() {
      location.href = "http://www.wzclxx.com/";
    },
    //选项卡点击事件
    onClickItem(option, index) {
      //改变当前选项
      this.currentIndex = index;
      //赋值
      this.form.message = option.title;
    },
    //联系我们
    contact() {
      location.href = "https://tb.53kf.com/code/client/10179248/1";
    },
    //页面验证计时器
    timerReg() {
      let that = this;
      that.is_click = false;
      var timer = setInterval(function () {
        that.verify = that.count_down-- + " S";
        if (that.count_down < -1) {
          clearInterval(timer);
          that.verify = "获取验证码";
          that.count_down = 60;
          that.is_click = true;
          return;
        }
      }, 1000);
    },
    //获取验证码
    get_code() {
      //1.手机号验证
      if (!this.phoneRule.test(this.form.userPhone)) {
        this.text = "手机号错误，请重新填写";
        this.$refs.popup.open();
        return;
      }
      //2.开启页面验证定时器
      this.timerReg();
      //3.获取验证码
      this.getCode();
    },
    //获取验证码
    getCode() {
      sendSms({
        phone: this.form.userPhone,
      }).then((res) => {
        if (res.data.code == 200) {
          this.text = res.data.message;
          this.$refs.popup.open();
        }
        if (!res.data.data) {
          this.text = res.data.message;
          this.$refs.popup.open();
        }
      });
    },
    //表单提交
    submit() {
      if (this.form.userName == "") {
        this.text = "姓名不能为空";
        this.$refs.popup.open();
        return;
      }
      if (this.form.userPhone == "") {
        this.text = "联系电话不能为空";
        this.$refs.popup.open();

        return;
      }
      if (this.form.message == "") {
        this.text = "请选择文理科";
        this.$refs.popup.open();
        return;
      }
      if (!this.phoneRule.test(this.form.userPhone)) {
        this.text = "手机号错误，请重新填写";
        this.$refs.popup.open();
        return;
      }
      submitUserInfo(this.form).then((res) => {
        if (res.data.code == 200) {
          this.text = "获取成功!";
          this.$refs.popup.open();
          //重置
          this.form = {
            userName: "", //姓名
            message: "", //文理科
            userPhone: "", //联系电话
            code: "", //验证码
            comeFrom: "高考冲刺移动端",
          };
        } else {
          this.text = "获取失败";
          this.$refs.popup.open();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    display: block;
  }
}

//封面
.cover {
  width: 100%;
  height: auto;

  image {
    width: 100%;
    display: block;
  }
}

//表单
.form {
  width: 100%;
  margin-top: 15px;

  .item {
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    height: 40px;
    color: #8e8e8e;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;

    span {
      display: inline-block;
      width: 25%;
      font-size: 17px;
    }

    input {
      width: 70%;
      border: 1px solid #f3f3f3;
      height: 40px;
      border-radius: 5px;
      padding-left: 10px;
      margin-left: 2.5%;
      font-size: 14px;
    }

    .options {
      width: 70%;
      display: flex;
      justify-content: space-between;

      .common {
        height: 30px;
        width: 45%;
        text-align: center;
        border-radius: 2.5px;
        background: #efefef;
        color: #000000;
        font-size: 15px;
        line-height: 30px;
      }

      .active {
        background: #fc3a30;
        color: #ffffff;
      }
    }

    .code {
      position: absolute;
      right: 35px;
      color: #000000;
      font-size: 15px;
    }
  }

  .phcolor {
    font-size: 13px;
    color: #c1c1c1;
  }

  //立即获取
  .button {
    width: 90%;
    height: 45px;
    text-align: center;
    color: #ffffff;
    box-sizing: border-box;
    line-height: 45px;
    background: linear-gradient(to bottom, #fe422d, #cf1412);
    border-radius: 5px;
    margin: 0 auto;
    font-size: 15px;
  }

  //提示
  .tip {
    text-align: center;
    font-size: 13px;
    line-height: 70px;
  }
}
.cursor {
  cursor: pointer;
}
// 轮播图
.banner {
  width: 100%;
}
</style>
