<template>
  <div class="popup-wrapper" v-show="visible" @click="hide">
    <span class="popup-text">{{ text }}</span>
  </div>
</template>
<script>
export default {
  name: "popup",
  props: {
    text: {
      //文字内容
      type: String,
      default: "",
    },
    time: {
      //显示的时长
      type: Number,
      default: 3e3,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
      clearTimeout(this.timeout);
      this.$emit("show");
      if (this.time > 0) {
        this.timeout = setTimeout(() => {
          this.hide();
        }, this.time);
      }
    },
    hide() {
      this.visible = false;
      this.$emit("hide");
      clearTimeout(this.timeout);
    },
  },
};
</script>
<style scoped>
.popup-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.popup-text{
    position: absolute;
    font-size: 14px;
    color: #ffffff;
    border-radius: 5px;
    background: rgba(0,0,0,0.5);
    height: 30px;
    line-height: 30px;
    text-align: center;
    padding: 0 15px;
    display: inline-block;
    min-width: 60px;
    left: 50%;
    transform: translateX(-50%);
    top: 25%;
    
}
</style>